const DATA =  [
    {  
        IsDead: false,
        Name: "Player01",
        Position: 0,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: true,
        Name: "Player02",
        Position: 1,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: false,
        Name: "Player03",
        Position: 2,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: true,
        Name: "Player04",
        Position: 3,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: false,
        Name: "Player05",
        Position: 4,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: true,
        Name: "Player06",
        Position: 5,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: false,
        Name: "Player07",
        Position: 6,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: true,
        Name: "Player08",
        Position: 7,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: false,
        Name: "Player09",
        Position: 8,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    },
    {  
        IsDead: true,
        Name: "Player10",
        Position: 9,
        RoleName: "Unknown",
        VoteTarget: "Unknown",
    }
]

export default DATA;
const list = [
  {
    Id: "46210ff2-1b9f-483d-8df7-354f5fc49042",
    Position: 0,
    Name: "muhah",
  },
  {
    Id: "33b37f10-bd1d-40bd-820f-252d704ae8d6",
    Position: 1,
    Name: "Bixkog",
  },
  {
    Id: "39176033-8a91-4e49-acc4-5ce297641d06",
    Position: 2,
    Name: "Spawek",
  },
  {
    Id: "133e5fcd-9f94-4837-8123-cf2fd1a67425",
    Position: 3,
    Name: "Dragon",
  },
  {
    Id: "c2e4bd69-80eb-43de-9861-9dfa938c719e",
    Position: 4,
    Name: "goovie",
  },
  {
    Id: "46210ff2-1b9f-483d-8df7-354f5fc49043",
    Position: 5,
    Name: "muhah",
  },
  {
    Id: "33b37f10-bd1d-40bd-820f-252d704ae8d7",
    Position: 6,
    Name: "Bixkog",
  },
  {
    Id: "39176033-8a91-4e49-acc4-5ce297641d07",
    Position: 7,
    Name: "Spawek",
  },
  {
    Id: "133e5fcd-9f94-4837-8123-cf2fd1a67428",
    Position: 8,
    Name: "Dragon",
  },
  {
    Id: "c2e4bd69-80eb-43de-9861-9dfa938c719f",
    Position: 9,
    Name: "goovie",
  },
  {
    Id: "46210ff2-1b9f-483d-8df7-354f5fc49053",
    Position: 10,
    Name: "muhah",
  },
  {
    Id: "33b37f10-bd1d-40bd-820f-252d704ae8e8",
    Position: 11,
    Name: "Bixkog",
  },
  {
    Id: "39176033-8a91-4e49-acc4-5ce297641d08",
    Position: 12,
    Name: "Spawek",
  },
  {
    Id: "133e5fcd-9f94-4837-8123-cf2fd1a67427",
    Position: 13,
    Name: "Dragon",
  },
  {
    Id: "c2e4bd69-80eb-43de-9861-9dfa938c719a",
    Position: 14,
    Name: "goovie",
  },
  {
    Id: "46210ff2-1b9f-483d-8df7-354f5fc49044",
    Position: 15,
    Name: "muhah",
  },
  {
    Id: "33b37f10-bd1d-40bd-820f-252d704ae8d8",
    Position: 16,
    Name: "Bixkog",
  },
  {
    Id: "39176033-8a91-4e49-acc4-5ce297641e08",
    Position: 17,
    Name: "Spawek",
  },
  {
    Id: "133e5fcd-9f94-4837-8123-cf2fd1a67447",
    Position: 18,
    Name: "Dragon",
  },
  {
    Id: "c2e4bd69-80eb-43de-9861-9dfa938c729a",
    Position: 19,
    Name: "goovie",
  },
  {
    Id: "46210ff2-1b9f-483d-8df7-354f5fc49064",
    Position: 20,
    Name: "muhah",
  },
  {
    Id: "33b37f10-bd1d-40bd-820f-252d704ae8d9",
    Position: 21,
    Name: "Bixkog",
  },
  {
    Id: "39176033-8a91-4e49-acc4-5ce297641d09",
    Position: 22,
    Name: "Spawek",
  },
  {
    Id: "133e5fcd-9f94-4837-8123-cf2fd1a67628",
    Position: 23,
    Name: "Dragon",
  },
  {
    Id: "c2e4bd69-80eb-43de-9861-9dfa938c719b",
    Position: 24,
    Name: "goovie",
  },
];
export default list;

import PLAYERS from "./LobbyUserBigMocks";

const State = {
  Players: PLAYERS,
  IsDay: false,
  DayNumber: 13,
  Winners: [],
};

export default State;
